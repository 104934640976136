import { useCallback } from 'react'
// Services
import { httpService } from 'core/data'
// Hooks
import { useGetActiveSite } from 'modules/sites'
import { useNotify } from 'core/hooks'
// utils
import { EntityReferencesRes, RevalidationService } from 'core/utils/revalidation-service'
// Types
import { IEntity } from 'modules/new-entity/types'

export const useRevalidate = () => {
  const activeSite = useGetActiveSite()
  const notify = useNotify()

  const getEntityUrls = useCallback(
    async (mainPath: string, entityId: number, entityType: 'entity' | 'media') => {
      try {
        const entityReq = httpService
          .get<IEntity>(`/${mainPath}/${entityId}`)
          .then((res) => res.data)
        const entityRefsReq = RevalidationService.getEntityReferences(entityId, entityType)
        const [entity, entityRefs] = await Promise.all([entityReq, entityRefsReq])
        const pageUrl = entity.entityUrls?.[0]?.url || null

        return [pageUrl, entityRefs] as [string | null, EntityReferencesRes]
      } catch (error: any) {
        console.log(error)
      }
    },
    []
  )

  const revalidateEntity = useCallback(
    async (
      entityId: number,
      entityType: 'entity' | 'media',
      pageUrl: string | null,
      entityRefs: EntityReferencesRes
    ) => {
      try {
        if (activeSite) {
          await RevalidationService.revalidateEntity(
            activeSite.url,
            entityId,
            entityType,
            pageUrl,
            entityRefs
          )
        }
      } catch {
        notify('Cache not revalideted', { type: 'warning' })
      }
    },
    [activeSite, notify]
  )

  const revalidateUrls = useCallback(
    async (urls: string[]) => {
      if (activeSite) {
        try {
          await RevalidationService.revalidateUrls(activeSite.url, urls)
        } catch {
          notify('Cache not revalideted', { type: 'warning' })
        }
      }
    },
    [activeSite, notify]
  )

  const revalidateAll = useCallback(async () => {
    if (activeSite) {
      try {
        await RevalidationService.revalidateAll(activeSite.url)
      } catch {
        notify('Cache not revalideted', { type: 'warning' })
      }
    }
  }, [activeSite, notify])

  return { getEntityUrls, revalidateEntity, revalidateUrls, revalidateAll }
}

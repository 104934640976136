import React, { useMemo, useState } from 'react'
import { EntityType } from 'core/types'
import QuickMutate from './quick-create'
import { useTypePermissions } from 'modules/new-entity/hooks'

type Actions = {
  createHandler?: () => void
  editHandler?: (id: number) => void
  deleteHandler?: (id: number) => void
  singleEditHandler?: (id: number) => void
  getViewPath?: (id: number) => string
  canDelete?: boolean
}

type Params = {
  entityType?: EntityType
  refetch: () => void
  onCreate?: (data: any) => void
}

export const useActions = ({ entityType, refetch, onCreate }: Params) => {
  const [crateOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState<number | null>(null)

  // @ts-ignore
  const permissions = useTypePermissions(entityType)

  const actions = useMemo(() => {
    const hasActions = !entityType || !entityType?.urlable
    const actions: Actions = {}

    if (permissions.canView) {
      actions.getViewPath = (id: number) => `/entities/${entityType?.id}/edit/${id}`
    }

    if (!hasActions) return actions

    if (permissions.canCreate) {
      actions.createHandler = () => setCreateOpen(true)
    }

    if (permissions.canEdit) {
      actions.editHandler = (id: number) => setEditOpen(id)
    }

    if (permissions.canDelete) {
      actions.canDelete = permissions.canDelete
    }

    return actions
  }, [entityType, permissions])

  const entityModal = useMemo(() => {
    if (!entityType) return null

    const isOpen = crateOpen || Boolean(editOpen)
    const isEdit = Boolean(editOpen)

    if (isOpen) {
      return (
        <QuickMutate
          entityId={editOpen as number}
          isOpen={isOpen}
          setIsOpen={() => {
            setCreateOpen(false)
            setEditOpen(null)
          }}
          entityTypeIri={entityType['@id']}
          isEdit={isEdit}
          onSuccess={(data) => {
            if (!isEdit) {
              onCreate?.(data)
            }
            refetch()
          }}
        />
      )
    }
    return null
  }, [entityType, crateOpen, editOpen, refetch, onCreate])

  return { actions, entityModal }
}

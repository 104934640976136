// Core
import React, { FC } from 'react'
import { Box, CircularProgress, MenuItem, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
// Components
import SlugLabel from 'ui/relations-select/slug-label'
// Hooks
import useEntitiesList from 'modules/eav/hooks/use-entities-list'
// Utils
import { getIdFromIri } from 'core/utils'

type Props = {
  siteId: number
  onSelect: (value: string) => void
  value: string[]
  search: string
}

export const SiteItem: FC<Props> = ({ siteId, onSelect, value, search }) => {
  const { isLoading, data, page, totalCount, setPage, site } = useEntitiesList({
    siteId,
    requestParams: {
      original: true,
    },
    filters: {
      _name: {
        contain: search,
      },
      status: {
        equal: 'publish',
      },
    },
  })

  return (
    <Box>
      {isLoading && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {data?.map((item) => {
        const defaultLocalization = site && getIdFromIri(site.defaultLocale)
        const localization = item.localization || defaultLocalization
        const localizationString = localization ? ` (${localization})` : ''
        const title = `${item.name}${localizationString}`

        return (
          <MenuItem
            key={item['@id']}
            button
            onClick={() => onSelect(item['@id'])}
            selected={value.includes(item['@id'])}
            style={{ whiteSpace: 'unset' }}
          >
            <Typography style={{ maxWidth: 250 }} component="div">
              {title}
              <SlugLabel entityUrls={item.entityUrls} />
            </Typography>
          </MenuItem>
        )
      })}
      {!isLoading && (
        <Pagination
          page={page}
          count={Math.round(totalCount / 30)}
          onChange={(e, page) => setPage(page)}
        />
      )}
    </Box>
  )
}

// Core
import React, { FC, useCallback } from 'react'
import { Form, Formik } from 'formik'
import { FormLang, httpService, LanguageProvider } from 'core/data'
import { Box, Button, CircularProgress, Grid } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
// Components
import FormControl from 'modules/new-entity/components/attributes-form/components/form-control'
import ResourceVersionSwitcher from 'modules/personalization/resource-version-switcher'
// Hooks
import { useInitData } from 'modules/new-entity/hooks/use-init-data'
import { useInitialValues } from 'modules/new-entity/hooks/use-initial-values'
import { useNotify } from 'core/hooks'
import { useGetNavigationContext } from 'modules/navigation/hooks'
import { useTranslation } from 'react-i18next'
import { useMutatePersonalizedVersion } from 'modules/new-entity/hooks'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Utils
import { getIdFromIri } from 'core/utils'
import { ValuesGenerator } from 'modules/new-entity/transformers'
// Types
import { EavResourceType, MutationType } from 'modules/new-entity/types'
// Styles
import useStyles from './navigation-base-form.styles'

type TProps = {
  type: MutationType
}

const NavigationBaseForm: FC<TProps> = (props) => {
  const { type } = props
  const { id } = useParams()
  const history = useHistory()
  const notify = useNotify()
  const classes = useStyles()
  const { t } = useTranslation()
  const { navigationId, isEdit, navigationType } = useGetNavigationContext()
  const { revalidateAll } = useRevalidate()

  /**
   * Getting form data with
   * all validation, form values, ET, other
   */
  const { data, isLoading, refetch } = useInitData(id, navigationType?.id, 'entity', {
    queryKey: 'navigation-form',
  })
  const { initialValues, validationSchema } = useInitialValues(data?.entity, data)

  const mutatePersonalizedVersion = useMutatePersonalizedVersion(data?.attributes, false)

  const submitHandler = useCallback(
    async (values) => {
      // @ts-ignore
      values = new ValuesGenerator(data?.attributes, values).getData()
      const entityValues: any = {
        entityType: data?.entityType['@id'],
        segments: data.entity.segments,
        ...values,
      }

      try {
        const httpMethod = !isEdit ? 'post' : 'put'
        const { data } = await httpService[httpMethod](
          !isEdit ? `/entities` : `/entities/${navigationId}`,
          entityValues
        )
        await revalidateAll()
        const navId = getIdFromIri(data['@id'])
        history.push(`/navigations/${navId}`)

        notify(t('notify.success'), { type: 'success' })
      } catch (e) {
        console.log('Error')
      }
    },
    [
      data?.attributes,
      data?.entity.segments,
      data?.entityType,
      history,
      isEdit,
      navigationId,
      notify,
      revalidateAll,
      t,
    ]
  )

  return (
    <>
      <ResourceVersionSwitcher
        resource="entities"
        resourceId={+id}
        basePath="navigations"
        mt={0}
        onVersionMutationSuccess={() => {
          return refetch()
        }}
        onVersionMutation={(values) =>
          mutatePersonalizedVersion(
            values.originalId,
            values.name,
            values.segments,
            values.resourceId
          )
        }
      />
      {!isLoading ? (
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form>
            <LanguageProvider notSystem>
              <FormLang />

              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Grid container spacing={3}>
                    {data?.attributes.map((item: any) => (
                      <FormControl
                        resourceType={EavResourceType.ENTITY}
                        key={item.id}
                        name={item.attribute['@id']}
                        data={item}
                        nested
                        options={data?.entityType.options}
                        allData={data?.attributes}
                        pathToHierarchicalOptions={item.attribute['@id']}
                        selfType={'entity'}
                      />
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Button fullWidth variant="contained" color="primary" type="submit">
                      {type === MutationType.CREATE ? t('global.create') : t('global.save')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </LanguageProvider>
          </Form>
        </Formik>
      ) : (
        <div className={classes.spiner}>
          <CircularProgress />
        </div>
      )}
    </>
  )
}

export default NavigationBaseForm

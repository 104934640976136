// Core
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import RelationsField from 'core/form/components/relations-field'
// Hooks
import { useConfirmation } from 'core/confirmation'
import useFieldController from 'core/form/hooks/use-field-controller'

const FIELD_NAME = 'defaultLocale'

const DefaultLocalization = () => {
  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()
  const [, , { setValue }] = useFieldController(FIELD_NAME)

  const onChangeHandler = useCallback(
    (value) => {
      setConfirmation({
        open: true,
        title: t('notify.change-localization-title'),
        content: t('notify.change-localization-desc'),
        onSuccess: () => setValue(value),
      })
    },
    [setConfirmation, setValue, t]
  )

  return (
    <RelationsField
      valueField={'@id'}
      name={FIELD_NAME}
      source={'localizations'}
      label={t('labels.defaultLocalization')}
      optionLabelField={(data: any) => `${data.code} ${data.name}`}
      skipSetValue
      onChange={onChangeHandler}
    />
  )
}

export default DefaultLocalization

// Core
import React, { FC, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
} from '@material-ui/core'
import { useQuery } from 'react-query'
import { DialogTitle } from 'ui'
import { useAppContext } from 'core/app'
import { httpService } from 'core/data'
import { Site } from 'modules/sites'
import { HydraResponse } from 'core/types'

type Props = {
  onClone: (sites: string[]) => void
  cloneLoading: boolean
} & DialogProps

type SitesResponse = HydraResponse<Site>

export const CloneEntity: FC<Props> = (props) => {
  const { user } = useAppContext()
  const { onClone, cloneLoading, ...dialogProps } = props
  const [selected, setSelected] = useState<string[]>([])

  const sitesQ = useQuery({
    queryKey: ['clone-sites'],
    queryFn: () =>
      httpService
        .get<SitesResponse>('/sites', { params: { pagination: false } })
        .then((res) => res.data['hydra:member']),
    select: (data) => {
      return data.filter((site) => user.sites.includes(site['@id']))
    },
  })

  const selectHandler = (value: string) => {
    const findIndex = selected.indexOf(value)

    if (findIndex >= 0) {
      setSelected(selected.toSpliced(findIndex, 1))
    } else {
      setSelected([...selected, value])
    }
  }

  const isSelected = (iri: string) => selected.includes(iri)

  return (
    <Dialog {...dialogProps} maxWidth="sm" fullWidth>
      <DialogTitle onClose={dialogProps.onClose}>Clone to another sites</DialogTitle>
      <DialogContent>
        {sitesQ.isLoading && (
          <Box textAlign="center" py={10}>
            <CircularProgress />
          </Box>
        )}
        <List dense disablePadding>
          {sitesQ.data?.map((site) => (
            <ListItem
              key={site.id}
              button
              onClick={() => selectHandler(site['@id'])}
              selected={isSelected(site['@id'])}
            >
              <ListItemIcon>
                <Checkbox edge="start" checked={isSelected(site['@id'])} />
              </ListItemIcon>
              <ListItemText primary={site.name} secondary={site.url} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Box
          flex={1}
          style={{ display: 'flex' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Selected: {selected.length}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClone(selected)}
            disabled={cloneLoading || sitesQ.isLoading}
          >
            Clone
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
